import Case from "case";
import { Formik } from "formik";
import { Link } from "gatsby";
import React, { useContext } from "react";
import { BiTrash } from "react-icons/bi";
import * as Yup from "yup";
import CartContext from "../../context/cart-context";
import { Price } from "../../utilities/price";

function CartBundleDetail({ bundleId, bundle, compact = false }) {
  const cartContext = useContext(CartContext);
  const cover = bundle.find((item) => item.category.includes("covers"));
  const liner = bundle.find((item) => item.category.includes("liners"));
  const accessories = bundle.filter((item) =>
    item.category.includes("accessories")
  );

  return (
    <div className="border border-grey-light rounded-lg px-4 py-4">
      <div className="flex flex-wrap justify-between items-top pt-4 gap-2">
        <Link to={cover.url}>
          <img
            src={cover.image}
            alt={cover.title}
            className="w-16 h-16 object-cover block rounded"
          />
        </Link>
        <div className="flex-1">
          <div className="flex flex-wrap items-start justify-between mb-2 gap-2">
            <h5 className="text-base font-medium flex-1">
              <Link className="no-underline text-grey-darkest" to={cover.url}>
                {cover.title} - {Case.title(cover.color)}
              </Link>
            </h5>

            <div className="grid gap-2 items-center">
              {!compact && (
                <div className="flex gap-2">
                  <Formik
                    initialValues={{ quantity: cover.quantity }}
                    onSubmit={(values, { setSubmitting }) => {
                      cartContext.setBundleQuantity(bundleId, values.quantity);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      quantity: Yup.number().required("Required"),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <input
                            type="number"
                            name="quantity"
                            value={values.quantity}
                            onChange={(e) => {
                              handleChange(e);
                              setTimeout(() => {
                                handleSubmit();
                              });
                            }}
                            onBlur={handleBlur}
                            min="1"
                            className={
                              errors.quantity && touched.quantity
                                ? "py-2 px-2 w-12 rounded border border-red-dark"
                                : "py-2 px-2 w-12 rounded border border-grey-light"
                            }
                          />
                          {errors.quantity && touched.quantity && (
                            <div className="text-red-dark">
                              {errors.quantity}
                            </div>
                          )}
                        </form>
                      );
                    }}
                  </Formik>
                  <button
                    aria-label="Remove from cart"
                    className="border border-grey-light rounded p-2"
                    onClick={() => {
                      cartContext.removeBundleFromCart(bundleId);
                    }}
                  >
                    <BiTrash size={20} />
                  </button>
                </div>
              )}
              <div className="text-right">
                <Price
                  value={cover.salePrice ? cover.salePrice : cover.basePrice}
                />
                {compact && <span> x {cover.quantity}</span>}
              </div>
            </div>
          </div>
          {liner && (
            <div className="flex gap-2 text-sm font-light mb-2">
              <img
                src={liner.image}
                alt={liner.title}
                className="w-12 h-12 object-cover block rounded"
              />
              <div>
                <h5>{liner.title}</h5>
                <p>
                  <Price
                    value={liner.salePrice ? liner.salePrice : liner.basePrice}
                  />
                </p>
              </div>
            </div>
          )}
          {accessories.map((accessory, index) => {
            return (
              <div className="flex gap-2 text-sm font-light mb-2" key={index}>
                <img
                  src={accessory.image}
                  alt={accessory.title}
                  className="w-12 h-12 object-cover block rounded"
                />
                <div>
                  <h5>{accessory.title}</h5>
                  <p>
                    <Price
                      value={
                        accessory.salePrice
                          ? accessory.salePrice
                          : accessory.basePrice
                      }
                    />
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CartBundleDetail;

export const navItems = [
  {
    title: "About",
    link: "/about/",
    subNav: [
      {
        title: "About Us",
        link: "/about/",
      },
      {
        title: `Why ${process.env.COMPANY_NAME_SHORT}`,
        link: "/why-custom-matters/",
      },
      {
        title: "FAQs",
        link: "/faq/",
      },
      {
        title: "Blog",
        link: "/blog/",
      },
      {
        title: "Contact Us",
        link: "/contact-us/",
      },
    ],
  },
  {
    title: "Contact Us",
    link: "/contact-us/",
  },
  {
    title: "Service Areas",
    link: "/service-areas/",
  },
];

export const productNavItems = [
  {
    title: "Window Well Covers",
    link: "/compare-covers/",
    subNav: [
      {
        title: "Steel Custom Window Well Cover",
        link: "/window-well-covers/?material=steel&style=standard&color=black",
      },
      {
        title: "Steel Custom with Polycarbonate Topper",
        link: "/window-well-covers/?material=steel&style=polycarbonate-topper&color=black",
      },
      {
        title: "Polycarbonate Custom Window Well Cover",
        link: "/window-well-covers/?material=polycarbonate&style=standard&color=clear",
      },
      {
        title: "All Covers",
        link: "/compare-covers/",
      },
    ],
  },
  {
    title: "Window Well Liners",
    link: "/window-well-liners/",
    subNav: [
      {
        title: "Brown Stone Liner",
        link: `/window-well-liners/?style=Brown%20Stone`,
      },
      {
        title: "Gray Stone Liner",
        link: `/window-well-liners/?style=Gray%20Stone`,
      },
      {
        title: "Gray Brick Liner",
        link: `/window-well-liners/?style=Gray%20Brick`,
      },
      {
        title: "White Brick Liner",
        link: `/window-well-liners/?style=White%20Brick`,
      },
      {
        title: "View all Liners",
        link: "/window-well-liners/",
      },
    ],
  },
  {
    title: "Accessories",
    link: "/window-well-accessories/",
    subNav: [
      {
        title: "EZ Lift System",
        link: "/ez-lift-systems/",
      },
      {
        title: "Window Well Ladders",
        link: "/window-well-ladders/",
      },
      {
        title: "Window Well Locks",
        link: "/window-well-locks/",
      },
    ],
  },
];

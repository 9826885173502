import Case from "case";
import { Formik } from "formik";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { BiTrash } from "react-icons/bi";
import * as Yup from "yup";
import CartContext from "../../context/cart-context";
import { Price } from "../../utilities/price";

function CartItemDetail(props) {
  const cartContext = useContext(CartContext);

  return (
    <div
      key={props.id}
      className="flex flex-wrap justify-between p-4 items-top border border-grey-light rounded-lg"
    >
      <div className="">
        <Link to={props.url}>
          <img
            src={props.image}
            alt={props.title}
            className="w-16 h-16 object-cover block rounded"
          />
        </Link>
      </div>
      <div className="flex-1 pl-4">
        <div className="flex flex-wrap items-center justify-between gap-2">
          <h5 className="text-base font-medium flex-1">
            <Link className="no-underline text-grey-darkest" to={props.url}>
              {props.title}
            </Link>
          </h5>

          <div className="grid gap-2">
            <div className="flex items-center gap-2">
              <Formik
                initialValues={{ quantity: props.quantity }}
                onSubmit={(values, { setSubmitting }) => {
                  cartContext.setItemQuantity(props.id, values.quantity);
                  setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                  quantity: Yup.number().required("Required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <input
                        type="number"
                        name="quantity"
                        value={values.quantity}
                        onChange={(e) => {
                          handleChange(e);
                          setTimeout(() => {
                            handleSubmit();
                          });
                        }}
                        onBlur={handleBlur}
                        min="1"
                        className={
                          errors.quantity && touched.quantity
                            ? "py-2 px-2 w-12 rounded border border-red-dark"
                            : "py-2 px-2 w-12 rounded border border-grey-light"
                        }
                      />
                      {errors.quantity && touched.quantity && (
                        <div className="text-red-dark">{errors.quantity}</div>
                      )}
                    </form>
                  );
                }}
              </Formik>
              <button
                aria-label="Remove from cart"
                className="border border-grey-light rounded p-2"
                onClick={() => {
                  cartContext.removeItemFromCart(props.id);
                }}
              >
                <BiTrash size={20} />
              </button>
            </div>
            <div className="text-right">
              <Price value={props.price} />
            </div>
          </div>
        </div>
        {props.attributes.map((item, index) => {
          return (
            <p className="text-xs font-light" key={index}>
              {Case.sentence(item.variationAttributeName)}:{" "}
              {Case.sentence(item.variationAttributeValue)}
            </p>
          );
        })}
      </div>
    </div>
  );
}

CartItemDetail.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  quantity: PropTypes.number,
  attributes: PropTypes.array,
};

export default CartItemDetail;

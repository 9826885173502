import classNames from "classnames";
import { navigate } from "gatsby";
import groupBy from "just-group-by";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { BiX } from "react-icons/bi";
import Drawer from "react-modern-drawer";
import CartContext from "../../context/cart-context";
import { styles } from "../../utilities/styles";
import CartBundleDetail from "./cart-bundle-detail";
import CartItemDetail from "./cart-item-detail";

const CartDrawer = () => {
  const cartContext = useContext(CartContext);
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 450) {
        setWidth("100%");
      } else {
        setWidth(450);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsOpen(() => cartContext.cartDrawerOpen);
  }, [cartContext.cartDrawerOpen]);

  const bundles = useMemo(() => {
    const bundleProds = cartContext.contents.filter(
      (product) => product.bundleId
    );

    return groupBy(bundleProds, (product) => product.bundleId);
  }, [cartContext.contents]);

  const closeDrawer = () => {
    setIsOpen((prevState) => !prevState);
    cartContext.setCartDrawerOpenStatus(false);
  };

  return (
    <Drawer open={isOpen} onClose={closeDrawer} direction="right" size={width}>
      <button
        className="absolute right-4 top-4"
        onClick={closeDrawer}
        type="button"
      >
        <BiX size={25} />
      </button>
      <div className="p-4 overflow-scroll h-full">
        {cartContext.contents.length === 0 && <p>Your cart is empty</p>}
        {!!cartContext.contents.length && (
          <>
            <h3 className="text-lg font-semibold mr-6">Your Cart</h3>
            <div className="mt-4 grid gap-4">
              {Object.entries(bundles).map(([key, bundle], index) => (
                <CartBundleDetail key={index} bundleId={key} bundle={bundle} />
              ))}
              {cartContext.contents.map((product, index) => {
                if (product.bundleId) return null;

                return (
                  <CartItemDetail
                    key={index}
                    id={product.id}
                    url={product.url}
                    image={product.image}
                    price={
                      product.salePrice ? product.salePrice : product.basePrice
                    }
                    title={product.title}
                    quantity={product.quantity}
                    attributes={product.attributes ? product.attributes : []}
                  />
                );
              })}
            </div>

            <div className="flex justify-between border-t border-grey-light my-4 py-4">
              <span>Subtotal</span>
              <span>{cartContext.totals.subtotal}</span>
            </div>
            <div className="text-right mb-16">
              <button
                onClick={() => {
                  cartContext.setCartDrawerOpenStatus(false);
                  navigate("/checkout/");
                }}
                className={classNames(
                  `inline-block tracking-wide px-8 py-2 no-underline rounded`,
                  styles.button.default
                )}
              >
                Checkout
              </button>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};
export default CartDrawer;
